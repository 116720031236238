import { AppSettings } from '@/core/app-ctx/AppSettings';
import { type LocalizationOverrides } from '@/core/localization/mod';
import { ThemeOverrides } from '@/core/theme/ThemeOverrides';
import { Entity } from '@/entity/A_Entity';
import { Document } from '@/entity/basic/Document';

export class Integration extends Entity {
    /**
     * TODO: make this a non static member
     * Checks whether the two integrations are the same.
     */
    public static isSameIntegration(integrationA?: Integration, integrationB?: Integration): boolean {
        return integrationA?.id === integrationB?.id;
    }

    public readonly name: string;

    public readonly email: string;

    public readonly companyName: string;

    public readonly url: string;

    public readonly translationOverrides: LocalizationOverrides;

    public readonly themeOverrides: ThemeOverrides;

    public readonly settings: AppSettings;

    public readonly singleCompany: boolean;

    public readonly allCompanies: boolean;

    public readonly logo?: Document;

    public readonly bookingLeadTime?: number;

    constructor(json: Record<string, any> = {}) {
        super(json);

        this.name = json.name;
        this.email = json.email;
        this.companyName = json.companyName;
        this.url = json.url;
        this.translationOverrides = json.translationOverrides ?? {};
        this.singleCompany = json.singleCompany;
        this.allCompanies = json.allCompanies;
        this.themeOverrides = new ThemeOverrides(json.themeOverrides);
        this.settings = new AppSettings(json.settings);
        this.logo = json.logo ? Document.fromJson(json.logo) : undefined;
        this.bookingLeadTime = json.bookingLeadTime || undefined;
    }

    public toString(): string {
        return `${this.name} - ${this.url}`;
    }

    public host(): string | undefined {
        return this.url == null || this.url.length === 0 ? undefined : new URL(this.url).host;
    }
}
