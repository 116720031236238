import * as Yup from 'yup';

import { type Account } from '@/entity/account/Account';
import { type ILocation, LocationSchema } from '@/entity/basic/Location';
import { type registrationSchema, type IRegistration } from '@/features/auth';

export type AccountCoreDataFormValues = Omit<IRegistration, 'password' | 'passwordConfirmation' | 'email'>;

export interface IContactData {
    readonly gender: string;
    readonly country: string;
    readonly city: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly postCode: string;
    readonly street: string;
    readonly phone: string;
}

export const contactDataSchema: Yup.ObjectSchema<IContactData> = Yup.object({
    gender: Yup.string().required(),
    country: Yup.string().required(),
    city: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    postCode: Yup.string().required(),
    street: Yup.string().required(),
    phone: Yup.string().required(),
});

export function getDefaultContactDataValues(user?: Account): IContactData {
    return {
        gender: user?.contactData.gender ?? '',
        country: user?.contactData.country ?? '',
        city: user?.contactData.city ?? '',
        firstName: user?.contactData.firstName ?? '',
        lastName: user?.contactData.lastName ?? '',
        postCode: user?.contactData.postCode ?? '',
        street: user?.contactData.street ?? '',
        phone: user?.contactData.phone ?? '',
    };
}

export interface IFullContactData extends IContactData {
    readonly email: string;
}

export const fullContactDataSchema: Yup.ObjectSchema<IFullContactData> = contactDataSchema.shape({
    email: Yup.string().email().required(),
});

export function getDefaultFullContactDataValues(user?: Account): IFullContactData {
    return {
        ...getDefaultContactDataValues(user),
        email: user?.contactData.email ?? '',
    };
}

export type ICustomerGroupValues = Yup.InferType<typeof customerGroupSchema>;

export const customerGroupSchema = Yup.object({
    customerGroupId: Yup.number().min(1, { key: 'general.required' }).required(),
    customerGroupName: Yup.string().required(),
    organizationName: Yup.string().when('customerGroupId', {
        is: (customerGroupId: number) => customerGroupId !== 4,
        then: schema => schema.required(),
    }),
    organizationLocation: Yup.object()
        .nullable()
        .when('customerGroupId', {
            is: (customerGroupId: number) => customerGroupId !== 4,
            then: schema =>
                schema
                    .shape(LocationSchema.fields)
                    .test('required_location', { key: 'general.required' }, value => value != null),
        })
        .transform(ol => ol) as Yup.ObjectSchema<ILocation | null>,
    organizationUID: Yup.string(),
    organizationPoNumber: Yup.string(),
    organizationCostCenter: Yup.string(),
    // Whether the customer wants to register a new school or association, that's not in the system yet
    registerOrganization: Yup.boolean(),
    // Registration number of a new association. Required, if the customer wants to register a new association
    registrationNumber: Yup.string().when(['registerOrganization', 'customerGroupId'], {
        is: (registerOrganization: boolean, customerGroupId: number) => registerOrganization && customerGroupId === 7,
        then: schema => schema.required(),
    }),
});

export function getDefaultCustomerGroupValues(customerGroupId = 0): ICustomerGroupValues {
    return {
        customerGroupId,
        customerGroupName: '',
        organizationName: '',
        organizationLocation: null,
        organizationUID: '',
        organizationPoNumber: '',
        organizationCostCenter: '',
        registerOrganization: false,
        registrationNumber: '',
    };
}

export interface IRegistrationFormValues extends Yup.InferType<typeof registrationSchema> {}

export function getDefaultRegistrationFormValues(): IRegistrationFormValues {
    return {
        ...getDefaultCustomerGroupValues(),
        contactData: getDefaultContactDataValues(),
        email: '',
        password: '',
        passwordConfirmation: '',
        agb: false,
    };
}
